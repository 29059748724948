import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "text-center py-5" }
const _hoisted_3 = { class: "flex flex-wrap" }
const _hoisted_4 = { class: "bg-white shadow" }
const _hoisted_5 = { class: "p-3" }
const _hoisted_6 = { class: "md:h-20" }
const _hoisted_7 = { class: "md:h-20" }
const _hoisted_8 = {
  key: 0,
  class: "h-96 text-center flex flex-col justify-center px-2"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "mt-6 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VerseBar = _resolveComponent("VerseBar")!
  const _component_ManuscriptSelect = _resolveComponent("ManuscriptSelect")!
  const _component_ImageHolder = _resolveComponent("ImageHolder")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_VerseBar),
    (_ctx.isLoaded(_ctx.manuscriptVerseResults))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('manuscripts.result_count', {
          count: _ctx.manuscriptVerseResults.payload.length,
          sura: _ctx.$route.params.sura,
          verse: _ctx.$route.params.verse,
        })), 1),
          _createVNode(_component_ManuscriptSelect, {
            sura: +_ctx.$route.params.sura,
            verse: +_ctx.$route.params.verse,
            selected: 0,
            justNavigation: true
          }, null, 8, ["sura", "verse"]),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.manuscriptVerseResults.payload, (r) => {
              return (_openBlock(), _createElementBlock("div", {
                key: r.manuscript_id,
                class: "w-full md:w-1/2 xl:w-1/3 my-5 p-2"
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_router_link, {
                    to: {
              name: 'ManuscriptPage',
              params: {
                manuscript: r.manuscript_id,
                page: r.pages[0].folio + r.pages[0].side,
              },
              query: {
                sura: _ctx.$route.params.sura,
                verse: _ctx.$route.params.verse,
              },
            }
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("p", _hoisted_6, [
                          _createElementVNode("b", null, _toDisplayString(r.title), 1)
                        ]),
                        _createElementVNode("p", _hoisted_7, _toDisplayString(r.archive.name), 1)
                      ]),
                      (typeof r.pages[0].images === 'string' || r.pages[0].images.length === 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            (r.pages[0].images.length === 0)
                              ? (_openBlock(), _createElementBlock("h3", _hoisted_9, _toDisplayString(_ctx.$t('global.no_image_available')), 1))
                              : (_openBlock(), _createElementBlock("h3", _hoisted_10, _toDisplayString(_ctx.$t('global.image_not_allowed')), 1))
                          ]))
                        : (_openBlock(), _createBlock(_component_ImageHolder, {
                            key: 1,
                            class: "h-96 text-center",
                            imageUrl: r.pages[0].images[0].image_url,
                            imageDescription: r.pages[0].images[0].image_source,
                            imageCaption: r.pages[0].images[0].image_source
                          }, null, 8, ["imageUrl", "imageDescription", "imageCaption"])),
                      _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.listPages(r.pages)), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ])
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}